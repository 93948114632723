import React from 'react'
import Link from 'components/Link'
import PropTypes from 'prop-types'
import theme from 'styles/theme'
import GA4 from 'utils/GA4'
import SandwichIcon from 'components/Icons/SandwichIcon'
import CloseIcon from 'components/Icons/CloseIcon'
import { Masthead, Logo, SandwichButton } from './HeaderMasthead.style'

const HeaderMasthead = (props) => {
  const {
    logos,
    navHandler,
    navOpen,
    languageSelectorOpen,
    showSkinny,
    showDark,
    shouldForceSandwichMenu,
    locale,
    closeNav,
    innerRef,
    villageSlug,
    villageName,
    valueRetailPage,
    servicesPage,
    translatedServices,
    forceWhiteLabels,
    backgroundColor,
  } = props
  const logo =
    logos && logos[0] && logos[0].code && logos[0].code.code
      ? logos[0].code.code
      : ''
  // DX-2157 to avoid SVG class name clash we change the class names of the header logo
  let formattedLogo = logo || ''
  for (let i = 0; i <= 5; i += 1) {
    const oldClassDeclare = `.st${i}{`
    formattedLogo = formattedLogo.replaceAll(oldClassDeclare, `.header${i}{`)
    const oldClass = `class="st${i}"`
    formattedLogo = formattedLogo.replaceAll(oldClass, `class="header${i}"`)
  }
  let color = showSkinny || showDark || navOpen ? 'coaldark' : 'white'
  if (typeof window !== 'undefined') {
    color = forceWhiteLabels && window.innerWidth > 900 ? 'white' : color
  }

  const colors = backgroundColor || theme.colors.white
  // Preparing Masthead Props
  const mastheadProps = {
    p: [3],
    zIndex: 1,
    flexDirection: ['row'],
    position: ['absolute'],
    top: '0',
    justifyContent: ['center', null, null, 'flex-start'],
    height: ['60px', null, showSkinny ? '70px' : '100px'],
    pt: ['9px', null, showSkinny ? '14px' : '29px'],
    backgroundColor: [navOpen ? colors : null, navOpen ? colors : null, null],
  }

  // Preparing SandwichButton Props
  const sandwichButtonProps = {
    navOpen,
    bg: 'transparent',
    onClick: navHandler,
    position: ['absolute'],
    right: [0, null, null],
    display: [
      'inline-block',
      null,
      shouldForceSandwichMenu ? 'inline-block' : 'none',
    ],
    top: ['5px', null, showSkinny ? '10px' : '25px'],
  }

  // Preparing Masthead Props
  const logoProps = {
    display: ['block', null, null],
    dangerouslySetInnerHTML: { __html: formattedLogo || logo },
    fill: color,
  }
  if (navOpen) {
    if (languageSelectorOpen) {
      logoProps.opacity = ['0.1', null, null]
      if (!shouldForceSandwichMenu) {
        logoProps.opacity[2] = ['1']
      }
    }
  }

  const translatedService = translatedServices && translatedServices[locale]

  const handleLogoClick = () => {
    if (navOpen) {
      closeNav()
    }
    GA4('header_logo_click', {
      village_name: villageName,
    })
  }

  return (
    <Masthead ref={innerRef} {...mastheadProps}>
      <Link
        to={`/${(valueRetailPage && 'value-retail/') ||
          (villageSlug && `${villageSlug}/`)}${locale && `${locale}`}${
          servicesPage && !valueRetailPage
            ? `/${translatedService}` || `/bookings`
            : ''
        }`}
        onClick={() => handleLogoClick()}>
        <Logo {...logoProps} />
      </Link>
      <SandwichButton {...sandwichButtonProps} fill={color}>
        {navOpen ? <CloseIcon width={[20]} /> : <SandwichIcon width={[20]} />}
      </SandwichButton>
    </Masthead>
  )
}

HeaderMasthead.propTypes = {
  forceWhiteLabels: PropTypes.bool,
  logos: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  navHandler: PropTypes.func.isRequired,
  navOpen: PropTypes.bool.isRequired,
  showSkinny: PropTypes.bool.isRequired,
  showDark: PropTypes.bool.isRequired,
  shouldForceSandwichMenu: PropTypes.bool.isRequired,
  languageSelectorOpen: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  closeNav: PropTypes.func.isRequired,
  villageSlug: PropTypes.string,
  villageName: PropTypes.string,
  innerRef: PropTypes.func,
  valueRetailPage: PropTypes.bool,
  servicesPage: PropTypes.bool,
  translatedServices: PropTypes.objectOf(PropTypes.string),
  backgroundColor: PropTypes.string,
}

HeaderMasthead.defaultProps = {
  forceWhiteLabels: false,
  logos: [],
  villageSlug: '',
  villageName: '',
  innerRef: () => {},
  valueRetailPage: false,
  servicesPage: false,
  translatedServices: null,
  backgroundColor: theme.colors.white,
}

export default React.forwardRef((props, ref) => (
  // @ts-ignore
  <HeaderMasthead {...props} innerRef={ref} />
))
